// https://css-tricks.com/forums/topic/fixed-position-misbehaviour-in-ie11/#post-204032

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  body, .fixed-header, .tab-bar.nav, nav.tab-bar, .in-settings .fixed.list-group, .add-tile-container {
    margin-left: 0;
  }

  @media (min-width: @screen-md-min)  {
    body, .fixed-header, .tab-bar.nav, nav.tab-bar, .add-tile-container {
      margin-left: 50vw;
    }

    .in-settings .fixed.list-group {
      margin-left: 0;
    }
  }

  .tile__icon .fa-spin {
    animation: none;
  }

  .not-app .tile--zoomed .tile__header {
    margin-left: calc(~"50vw + 1px");
  }

  .tile--zoomed .tile__body {
    position: static;
  }

  .tab-bar__tabs {
    justify-content: space-around;
  }

  .modal-content {
    height: 100%;
  }
}
