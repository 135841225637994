/* text-tile */
.text-tile-transition-group {
  position: absolute;
  top: 0;
  left: @tile-padding;
  right: @tile-padding;
  bottom: @tile-padding;

  .tile__item {
    position: absolute;
    width: 100%;
  }
}

.text-tile-enter {
  display: block;
  transform: translate(130%, 0) !important;
}

.text-tile-enter-active {
  transform: translate(0, 0) !important;
  .animation-enabled & {
    transition: transform 500ms ease-in-out;
  }
}

.text-tile-leave {
  display: block;
  transform: translate(0, 0) !important;
}

.text-tile-leave-active {
  transform: translate(-130%, 0) !important;
  .animation-enabled & {
    transition: transform 500ms ease-in-out;
  }
}

.grow-shrink-enter, .grow-shrink-appear {
  transform: scale(0, 0);

  &.grow-shrink-enter-active, &.grow-shrink-appear-active {
    transform: scale(1, 1);
    .animation-enabled & {
      transition: transform 200ms ease-out;
    }
  }
}

.grow-shrink-leave {
  transform: scale(1, 1);

  &.grow-shrink-leave-active {
    transform: scale(0, 0);
  }
}

.grow-shrink-modal-enter, .grow-shrink-modal-appear {
  .modal-backdrop {
    opacity: 0;
    transition: opacity 200ms ease-out;
  }

  .modal-content {
    transform: scale(0, 0);
    .animation-enabled & {
      transition: transform 200ms ease-out;
    }
  }

  &.grow-shrink-modal-enter-active, &.grow-shrink-modal-appear-active {
    .modal-backdrop {
      opacity: 0.5;
    }

    .modal-content {
      transform: scale(1, 1);
    }
  }
}

.grow-shrink-modal-leave {
  .modal-backdrop {
    opacity: 0.5;
    transition: opacity 200ms ease-in;
  }

  .modal-content {
    transform: scale(1, 1);
    .animation-enabled & {
      transition: transform 200ms ease-in;
    }
  }

  &.grow-shrink-modal-leave-active {
    .modal-backdrop {
      opacity: 0;
    }

    .modal-content {
      transform: scale(0, 0);
    }
  }
}

.btn-slide-enter, .btn-slide-appear {
  .animation-enabled & {
    transition: all .3s ease-in-out;
  }
  visibility: hidden;
  opacity: 0;
  transform: translate3d(25px, 0, 0);

  &.btn-slide-enter-active, &.btn-slide-appear-active {
    visibility: visible;
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.btn-slide-leave {
  .animation-enabled & {
    transition: all .3s ease-in-out;
  }
  visibility: visible;
  opacity: 1;
  transform: translate3d(0, 0, 0);

  &.btn-slide-leave-active {
    visibility: hidden;
    opacity: 0;
    transform: translate3d(25px, 0, 0);
  }
}
