.me-view--editing .tile:not(.tile--editing):not(.tile--hidden) {
  opacity: .7;
}

.me-view--editing {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
    // prevent bottommost tile resize btn being obscured by '+' btn
  padding-bottom: @tile-height * 0.6;

  .tile, a {
    cursor: default;
  }

  .animation-enabled &.with-transitions {
    .tile {
      transition: min-height .3s, height .3s;
    }
    .me-view__tiles .react-grid-item:not(.react-draggable-dragging) {
      transition: width .3s, height .3s, transform .3s;
    }
  }
}

.tile-zoom-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background-color: black;
  opacity: 0.35;
}

.me-view {
  h3, &.me-view--adding p {
    margin: 20px 0 20px (@tile-gutter + 10px);
  }
}

.in-edit.in-add {
  .settings-btn {
    display: none;
  }
}

.me-view-container {
  width: 100%;
  overflow: hidden;

  :not(.me-view--editing) {
    .tile {
      top: 0;
      left: 0;
      width: 100%;
      .animation-enabled & {
        transition: all .2s;
      }
      .tile__body {
        transition: opacity .2s;
      }
    }
  }
}

.tile-zooming .me-view-container {
  .tile:not(.tile--zoomed) {
    opacity: 0;
  }
  .tile--zoomed {
    width: 100vw;
    .tile__header {
      left: auto;
      right: auto;
      top: auto !important;
      margin-right: 0 !important;
      .tile__icon--right {
        display: none;
      }
    }
    .tile__body {
      opacity: 0;
    }
  }
}