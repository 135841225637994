@import (reference) '@universityofwarwick/id7/less/variables.less';
@import '@universityofwarwick/id7/less/id7-no-fa.less';
@import '@universityofwarwick/id7/less/font-awesome-pro';

.apply-brand(@id7-brand-default);

@id7-image-root-dir: '../lib/id7/images';
@icon-font-path: '../lib/fontawesome-pro';
@fa-font-path: '../lib/fontawesome-pro';

@import 'variables';
@import 'fatal-error';
@import 'id7-tweaks';
@import 'modals';
@import 'activity-item';
@import 'app-icon';
@import 'news-item';
@import 'tab-bar';
@import 'themes';
@import 'masthead-nav';
@import 'permission-request';
@import 'mobile';
@import 'top-page-notice';
@import 'notifications';
@import 'utilities';
@import 'me-view';
@import 'transitions';
@import 'tiles/tile';
@import 'tiles/tile-elements';
@import 'tiles/tile-modifiers';
@import 'tiles/edit-controls';
@import 'tiles/lists';
@import 'tiles/overrides';
@import 'tiles/map';
@import 'tiles/text';
@import 'search';
@import 'multiselect';
@import 'settings';
@import 'switches';
@import 'desktop-rendition';
@import 'ie-mitigation';
@import 'isrg-root-test';

body {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow-y: scroll;
  -webkit-overflow-scrolling: auto;
}


.react-loading, .tile-loading {
  position: relative;
  top: 35vh;
  width: 100%;
  text-align: center;
  font-size: 2em;
  color: #666666;
}

.tile-loading {
  color: #fff;
  z-index: 1;
  font-size: 32px;
}

.text-overflow-block {
  .text-overflow();
  display: block;
}

i.fas:before i.far:before i.fal:before {
  // FA5 icons are a big bigger than in FA4 so make them a bit smaller
  display: block;
  transform: scale(0.9);
}

.update-popup {
  background-color: #333;
  color: white;
  position: fixed;
  bottom: @start-tab-bar-height;
  left: 0;
  right: 0;
  z-index: 200;
  padding: 16px;
  margin: 4px;
  border-radius: 4px;

  .app.android & {
    bottom: 4px;
  }

  .media-body {
    padding: @padding-base-vertical + 1px 0;
  }
}
