.badge--red {
  background-color: #ff0404;
  border-radius: 100px;
  color: #fff;
  left: 206px;
  padding: 2px 10px;
  position: absolute;
  top: 56px;
}

ul.settings-list {
  i {
    margin-left: 16px;
    cursor: pointer;

    &.active {
      color: #5b3069
    }
    &.inactive {
      color: #9c9c9c
    }
  }

  li.settings-list-item {
    list-style: none;

    &.disabled {
      pointer-events: none;
      opacity: 0.4;
    }
  }
}
