.tile {
  height: @tile-height;
  position: relative;
  font-size: 13px;
  line-height: 1.2em;
  color: #fff;

  a {
    color: inherit;
    text-decoration: none;
  }

  .tile__body {
    overflow: hidden;
    html.ios & {
      font: -apple-system-footnote;
      line-height: 1.2em;
    }
  }
}

.tile--hidden {
  .tile__body {
    text-align: center;
    overflow: hidden;
  }
}

.tile--hidden__icon {
  font-size: @tile-height / 3;
  margin-bottom: @tile-height / 10;
}

.tile--hidden__title {
  font-size: @tile-height / 8;
}

.react-grid-item--editing {
  z-index: 10;
}

.me-view {
  // So MeView is offsetParent of react-grid-items
  position: relative;
  margin: 0 -@tile-gutter;
}

.tile__drag-handle {
  touch-action: none;
}

.react-draggable-dragging {
  z-index: 10;
}
