.permission-request {
  padding: 0.3em;
  text-align: center;
  background-color: #ffcc66;

  .permission-notice {
    display: inline-block;
    width: 95%;
    a {
      cursor: pointer;
    }
  }

  .permission-dismiss {
    display: inline-block;
    width: 5%;
    vertical-align:top;
    cursor: pointer;
  }

}

.permission-warning {
  .alert;
  .alert-warning;
  margin: 10px;

}

.permission-request, .permission-warning {
  position: absolute;
  bottom: @start-tab-bar-height;
  left: 0;
  z-index: 100;
  width: 100%;
}