.tile--text__container, .tile--workarea__container, .tile--library__container {
  .tile-text__controls {
    .btn-link {
      color: #fff;
    }

    button.previous {
      position: absolute;
      left: 0;
      bottom: 0;
    }

    button.playpause {
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
      bottom: 0;
      margin: 0;
    }

    button.next {
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
}