@import 'mixins';

// TODO should work together with the ID7 theme, rather than fighting it.

.behind-tiles({
  background-color: @behind-tiles-colour;
});

// DEFAULT COLOUR THEME
.theme-default {
  .tile-theme(
    #5F858F,
    #a1688a,
    #A8625C,
    #963E3E
  ) !important;
}

.theme-contrast {
  .tile-theme(#000,#000,#000,#000);
}

.theme-dodgeball {
  .tile-theme(
    #2C2C74,
    #584269,
    #730402,
    #8C4300
  ) !important;
}

.theme-orangery {
  .tile-theme(
    #BD7426,
    #AB5C24,
    #9C4A21,
    #85351B
  ) !important;
}

// Just for testing, obvs. This must never see the light of day
.theme-nightmare {
  .tile-theme(cyan, yellow, magenta, limegreen);

  // Themes now applied to body, so this is on the body

  .behind-tiles({
    background-color: white;
    background-image: radial-gradient(midnightblue 9px, transparent 10px),
    repeating-radial-gradient(midnightblue 0, midnightblue 4px, transparent 5px, transparent 20px, midnightblue 21px, midnightblue 25px, transparent 26px, transparent 50px);
    background-size: 30px 30px, 90px 90px;
    background-position: 0 0;
  });

  .id7-left-border, .id7-right-border, .id7-utility-masthead { background-color: gold; }
}

.transparent-theme-maker-helper(@baseColour, @gradBg, @gradTh, @imageUrl, @highContrastFallback) {

  html.not-app& {
    background: @baseColour;
    #app-container:after, header.id7-page-header:before {
      background-color: @baseColour;
    }
  }

  .start-masthead {
    background-color: @baseColour;
  }

  .bg-stand-in, body {
    background: @behind-tiles-colour;
  }

  @transparent: rgba(0, 0, 0, 0.25);
  .id7-fixed-width-container,
  .id7-main-content-area {
    background: transparent;
  }

  .in-root, .in-edit, .in-tiles {

    & when (iscolor(@highContrastFallback)) {
      html& .bg-stand-in {
        background-color: @highContrastFallback;
      }
    }

    & when (@highContrastFallback = null) {
      html& .bg-stand-in {
        background-image: url(@imageUrl), @gradBg;
      }
    }

    &.in-add {
      h3 {
        margin: 0;
        padding: 20px 0 20px 14px;
        background: @transparent;
        color: #fff;
      }
      .empty-state {
        color: contrast(@baseColour);
        text-align: center;
        margin-top: ~"calc(50vh - @{start-masthead-and-tab-bar-height} - 10vh)";
      }

    }

    html& {
      // Native apps render the background image internally
      background: transparent;
    }
  }

  .in-settings.in-tiles {
    &, html& .bg-stand-in {
      background: @behind-tiles-colour;
    }
  }

  .colour-0, .colour-1, .colour-2, .colour-3 {
    background-color: @transparent;
      .tile--zoomed .tile__header {
        & when (iscolor(@highContrastFallback)) {
            background: fade(multiply(@baseColour, @transparent), 80%);
          html.not-app& {
            background: fade(multiply(@highContrastFallback, @transparent), 80%);
          }
        }
        & when (@highContrastFallback = null) {
          background: @gradTh;
      }
    }
  }

  .tile--map__container .map-tile-image {
    -webkit-filter: saturate(0%) brightness(90%) contrast(50%) opacity(25%); // Android WebView seems to need this
    filter: saturate(0%) brightness(90%) contrast(50%) opacity(25%);
  }

  .add-tile-container .add-tile-button {
    background-color: @baseColour;
  }
}

.transparent-theme-maker(@baseColour, @gradBg, @gradTh, @imageUrl, @n) {
  .theme-transparent-@{n} {
    .transparent-theme-maker-helper(@baseColour, @gradBg, @gradTh, @imageUrl, null)
  }
  .theme-transparent-@{n}--high-contrast {
    .transparent-theme-maker-helper(@baseColour, @gradBg, @gradTh, @imageUrl, multiply(@baseColour, darken(white, 20%)))
  }
}

.transparent-theme-maker(
  #8C6E96,
  linear-gradient(to bottom, #0473de, #eca9de 45.7%, #eea7e0 45.7%, #f49aa2 70.9%, #f697a2 70.9%, #6d4674),
  linear-gradient(to right, rgba(82, 71, 144, 0.8) 0%, rgba(59, 98, 172, 0.8) 50%, rgba(55, 85, 158, 0.8) 100%),
  '/assets/images/bg01.jpg',
  1
);

.transparent-theme-maker(
  #AC554E,
  linear-gradient(to bottom, #e66b63, #f6b075 72.9%, #f7ae77 72.9%, #e48060 85.1%, #e57e5f 85.1%, #d46049 93.8%, #d55e4a 93.8%),
  linear-gradient(to right, rgba(130, 45, 60, 0.8) 0%, rgba(186, 116, 86, 0.8) 50%, rgba(179, 131, 98, 0.8) 100%),
  '/assets/images/bg02.jpg',
  2
);

.transparent-theme-maker(
  #338888,
  linear-gradient(to bottom, #95e1df 0%, #b7e4cf 34.3%, #b5e5cd 34.3%, #abce6a 63.3%, #accc6b 63.3%, #53a72d 76.4%, #51a92f 76.4%, #3ba21b),
  linear-gradient(to right, rgba(75, 161, 155, 0.8) 0%, rgba(155, 176, 165, 0.8) 50%, rgba(170, 179, 170, 0.8) 100%),
  '/assets/images/bg03.jpg',
  3
);

.transparent-theme-maker(
  #2F6B88,
  linear-gradient(to bottom, #01567f 0%, #035d82 1.3%, #025b83 1.3%, #026086 2.8%, #016285 2.8%, #047794 6.3%, #037993 6.3%, #1f8d9e 27.1%, #1f8f9d 27.1%, #a6ccc1 48.5%, #aed2c6 50.9%, #aecfc6 50.9%, #024b6b 86.3%, #034d6a 86.3%, #005572),
  linear-gradient(to right, rgba(5, 80, 100, 0.8) 0%, rgba(6, 86, 104, 0.8) 50%, rgba(5, 99, 114, 0.8) 100%),
  '/assets/images/bg04.jpg',
  4
);

.transparent-theme-maker(
  #A35138,
  linear-gradient(to bottom, #eef1ac, #fce178 12.9%, #fede77 12.9%, #ed8854 59.9%, #ef5a08 83.1%, #f15808 83.1%, #e85704 89.8%, #e75905 89.8%, #e7650f 92.4%, #e56710 92.4%, #f08d38 96.7%, #ed8f38 96.7%, #f7a655),
  linear-gradient(to right, rgba(180, 152, 31, 0.8) 0%, rgba(189, 164, 105, 0.8) 50%, rgba(191, 137, 61, 0.8) 100%),
  '/assets/images/bg05.jpg',
  5
);

.transparent-theme-maker(
  #526375,
  linear-gradient(137deg, rgb(253, 155, 20) 0%, rgb(254, 167, 70) 88%, rgb(255, 167, 78) 100%),
  linear-gradient(to right, #bf9a3d 0%, #b98b79 50%, #435977 100%),
  '/assets/images/bg06.jpg',
  6
);

.transparent-theme-maker(
  #597B56,
  linear-gradient(168deg, rgba(181,52,38,1) 0%, rgba(247,151,55,1) 100%),
  linear-gradient(to right, #ac5b1b 0%, #b84 25%, #867d4a 50%, #637f58 75%, #588068 100%),
  '/assets/images/bg07.jpg',
  7
);

.transparent-theme-maker(
  #383838,
  linear-gradient(90deg, rgba(109,109,109,1) 0%, rgba(34,34,34,1) 100%),
  #4f4f4f,
  '/assets/images/bg08.jpg',
  8
);


.behind-tiles(@rules) {
  html {
    background: transparent;
  }
  .not-app body {
    @rules();
  }
  .id7-fixed-width-container,
  .id7-main-content-area {
    @rules();
  }
}

// Mixin to generate classes for tiles
.tile-theme(@c0, @c1, @c2, @c3) {
  .colour-0 {
    &, & .tile__header {
      background-color: @c0;
    }
  }
  .colour-1 {
    &, & .tile__header {
      background-color: @c1;
    }
  }
  .colour-2 {
    &, & .tile__header {
      background-color: @c2;
    }
  }
  .colour-3 {
    &, & .tile__header {
      background-color: @c3;
    }
  }
}

.theme-transparent-8 .id7-utility-bar .img-circle,
.theme-transparent-8 .tile--account .flex-photo img.media-object {
  filter: saturate(0%);
}
