@import 'mixins';

.fixed-header {
  z-index: 101;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;

  .id7-fixed-width-container {
    height: auto !important;
    background: transparent !important;
  }
}

.id7-main-content-area > header {
  width: auto;
}

.app {
  // Content area sits above native tab bar, so don't need a gap.
  margin-bottom: 0;
  .tab-bar {
    display: none;
  }
}

.id7-fixed-width-container {
  z-index: auto !important;
}

.id7-masthead {
  padding-bottom: 14px;
}

.id7-fixed-width-container,
.id7-main-content-area {
  background: none;
}



.id7-main-content-area {
  // ID7 sets overflow-x: hidden, which creates odd scrolling behaviour
  overflow-x: unset;
}
.id7-main-content-area .id7-main-content {
  padding: 0;
}


.fa > .badge {
  // Ensure an icon badge uses the base font-family, not the FA icon font
  font-family: @font-family-base;
}

.fa-xs {
  font-size: 0.75em;
}
