.modal {
  display: flex;
  flex-direction: column;
  z-index: 410;

  // Allow selecting text
  &.selectable {
    .modal-body, .modal-title span {
      user-select: text;
    }
  }
}

.modal-backdrop {
  z-index: 400;
}

html.app .modal-dialog {
  height: ~"calc(100vh - 1em)"; // No navbar in WebView
}

html.not-app .modal-dialog {
  height: ~"calc(100vh - @{start-tab-bar-height} - 1em)";
}
html.ios.not-app .modal-dialog {
  height: ~"calc(100vh - @{ios-tab-bar-height} - 6em)";
}

.modal-content {
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  max-height: 100%;
  background-color: #ffffff;
}

.modal-footer, .modal-header {
  flex-shrink: 0;
}

.modal-body {
  overflow-y: auto;
  min-height: 0;
  flex: 1 1 auto;
  word-wrap: break-word;
  background-color: #f0f0f4;
  max-height: 100%;

  a {
    color: inherit;
    text-decoration: none;
  }
}

.modal-dialog {
  overflow-y: hidden;
  max-height: 100%;
  max-width: 95%;
}

.modal-title small {
  display: block;
  line-height: @modal-title-line-height;
}

// More native looking mobile modals

html {
  // styles common to all platforms

  .modal-dialog button.close {
    display: none;
  }

}

html:not(.ios) {
  // make modals resemble native Android modals on Android

  .modal-header h4.modal-title {
    color: #000;
    font-weight: 500;
  }

  .modal-header {
    border-bottom: none;
  }

  .modal-body {
    color: #878787;
  }

  .modal-content {
    border-radius: 2px;
  }

  .modal-footer {
    border-top: none;

    .btn {
      text-transform: uppercase;
      background: transparent;
      border: none;

      &:not(:disabled):hover {
        background-color: rgba(153, 153, 153, 0.2) !important;
      }
    }

    a, button {
      &.btn-primary, &.btn-default {
        color: #0093f7 !important; // Bootstrap uses !important on .btn-{class} :(
        font-weight: 500;
      }
    }

    .btn:disabled {
      color: rgba(0, 0, 0, 0.3) !important;
    }
  }
  .animation-enabled .modal-footer .btn {
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms; // Material-UI
  }

}

html.ios {
  // make modals resemble native iOS modals on iOS

  div .modal-content {
    font: -apple-system-body;
  }

  .modal-header h4.modal-title {
    font-weight: 500;
    text-align: center;
  }

  .modal-body {
    color: #797979;
  }

  .modal-footer {
    border-top: 1px solid #e5e5e5;

    .btn {
      background: transparent;
      border: none;
      box-shadow: none;

      &:active:not(:disabled) {
        background-color: #cdcdcd !important;
      }
    }

    a, button {
      &.btn-primary, &.btn-default {
        color: #0069f8 !important; // Bootstrap uses !important on .btn-{class} :(
        font-weight: 400;
      }
    }

    button:disabled.btn-primary, button:disabled.btn-default {
      color: #5d5d5d !important;
    }
  }
}
