html {

  &.ios body {
    h1, h2, h3 {
      font: -apple-system-headline;
    }

    h3, h4, h5 {
      font: -apple-system-subheadline;
    }
  }

  body {
    font-family: -apple-system, 'Roboto', 'Droid Sans', 'Segoe WP', Helvetica, sans-serif !important;

    overflow-x: hidden;

    min-height: unset;

    margin-top: @start-masthead-height;
    &.masquerading {
      margin-top: calc(@start-masthead-height + @top-page-notice-height);
    }
    margin-bottom: @start-tab-bar-height;
  }

  &.app.ios .update-popup {
    bottom: 0;
  }

  &.app.android body {
    margin-top: 0;
    margin-bottom: 0;
  }

  .tile__body {
    overflow-y: hidden;
  }
  .tile--zoomed .tile__body {
    overflow-y: auto;
  }
}

// MASTHEAD MOBILE
.start-masthead {
  z-index: 40;
  color: #fff;
  height: @start-masthead-height;
  line-height: 42px;
  position: relative;

  .back-btn {
    position: absolute;
    left: 0;
    top: 0;
    line-height: 44px;
    font-size: 1.25em;
    padding: 0 5px;
    cursor: pointer;

    i {
      padding: 0 5px;
    }
  }

  .settings-btn {
    position: absolute;
    left: 0;
    top: 0;
    line-height: 44px;
    font-size: 1.25em;
    padding: 0 13px;
    cursor: pointer;
  }

  .edit-btn {
    position: absolute;
    left: 44px;
    top: 0;
    line-height: 44px;
    font-size: 1.25em;
    padding: 0 13px;
    cursor: pointer;
  }

  .masthead-title {
    font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 1.5em;
    text-align: center;
    letter-spacing: -.2px;
    text-transform: uppercase;
    color: #fff;
    margin: 0;
    line-height: 42px;

    span.light {
      opacity: 0.6;
    }
  }

  .sign-in-link {
    line-height: 44px;
    font-size: 1.25em;
  }

  .id7-utility-bar {
    height: initial;
    line-height: 40px;
    position: absolute;
    right: 0;
    top: 0;
    padding: 0;

    > ul > li {
      margin: 0 !important;
      > img {
        width: 30px;
        height: 30px;
        margin: 2px 10px 0;
      }
    }
  }

  .popover.account-information {
    max-width: none !important;
  }
}

.in-edit .edit-btn {
  line-height: normal;
  padding: 2px;
  border-radius: 100%;
  background-color: transparent;
  border: 2px solid rgba(255, 255, 255, 0);
  margin: 7px 11px 7px 9px;
}

.in-edit .pulse {
  animation: pulse 1.1s 2;
}

@keyframes pulse {
  0% {
    background-color: transparent;
  }

  50% {
    background-color: #007aff;
  }

  100% {
    background-color: transparent;
  }
}

.in-tiles {
  &, .id7-main-content-area {
    overflow-y: hidden;
    -webkit-overflow-scrolling: auto;
  }

  .settings-btn {
    display: none;
  }
}

html.android.app {
  .start-masthead {
    display: none;
  }

  .suggested-links > h3 {
    display: none;
  }
}

.list-group-item[role="button"] {
  transition: background-color 0.1s;
  &:active {
    background-color: darken(@list-group-bg, 3%);
  }
  &[aria-disabled="true"] {
    cursor: default;
  }
}

html :hover {
  outline: none;
}

