@import 'animations';

.tile__wrap {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  html.ios & {
    font: -apple-system-body;
  }
}

.tile__header {
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  z-index: 1;
}

.tile__title {
  font-size: 16px;
  line-height: 29px;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 0 30px;
  color: #fff;
  html.ios & {
    font-size: 1em;
    font-weight: normal;
  }
}

.tile__icon {
  cursor: pointer;
  display: block;
  font-size: 16px;
  i {
    padding: @tile-padding;
    &.fa-heart {
      .heartbeat
    }
  }
}

.tile__icon--left {
  float: left;
}

.tile__icon--right {
  float: right;
  i {
    padding-right: @tile-padding + 2;
  }
}

.tile__body {
  padding: 2px @tile-padding @tile-padding;

  div[class*="col-"] {
    padding-left: @tile-body-col-padding;
    padding-right: @tile-body-col-padding;
  }

  position: absolute;
  top: 30px;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.tile__callout {
  font-size: 1.6em;
  line-height: 1;
}

.tile__calloutSub {
  display: block;
  font-size: 1.25em;
  line-height: 1;
}

.tile__item {
  margin-bottom: 1em;
}

.tile__text {
  display: block;
  html.ios & {
    font-size: 1em;
    line-height: 1;
  }
}


.tile-list-group .list-group-item--header {
  background-color: rgba(255, 255, 255, .2) !important;
  padding-top: .3em;
  padding-bottom: .3em;
  margin-bottom: .2em;
}

