.tile--large {
  height: @tile-height * 2 + @tile-gutter;
}

.tile--tall {
  height: @tile-height * 4 + 3 * @tile-gutter;
}

.tile--editing {
  .tile__edit-control {
    display: inline-block;
  }
}

.tile--zoomed {
  margin: 0;
  height: auto;

  html.app & {
    bottom: 0;
  }

  html.android.app & {
    top: 0;
  }
}

body.in-tiles {
  overflow: visible;
}

@tile-header-height: 30px;
@tile-header-zoomed-padding: 8px;
@tile-header-zoomed-height: 40px;
@tile-header-zoomed-padding-top: 3px;

.tile--zoomed {
  overflow: visible;

  .tile__header {
    position: fixed;
    max-width: 100vw;
    margin-left: auto;
    left: 0;
    right: 0;
    z-index: 10;
    height: @tile-header-zoomed-height;
    padding-top: 3px;
    padding-left: 3px;
  }

  .tile__icon {
    font-size: 20px;
  }

  .tile__title {
    font-size: 24px;
    padding-top: 2px;
    margin: 0;
    color: #fff;
    html.ios & {
      font-size: 1.4em;
      padding-top: 3px;
    }
  }

  .tile__wrap {
    position: unset;
    overflow: visible;
  }

  .tile__body {
    position: unset;
    overflow-y: visible;
    padding-top: (@tile-header-zoomed-height + @tile-header-zoomed-padding-top);
  }

  min-height: ~"calc(100vh - @{start-masthead-and-tab-bar-height})";
}

html.android.app .tile--zoomed {
  min-height: 100vh;
  .tile__header {
    top: 0;
  }

  html body.in-tiles .update-popup {
    display: none;
  }
}
