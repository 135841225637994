.in-settings, .in-post_tour {
  .media {
    overflow: visible;
  }

  .container-fluid.top {
    margin-top: @line-height-computed / 2;
  }

  .list-group {
    margin-bottom: 1em;

    &.setting-colour-0, .setting-colour-0 {
      .media-left, .list-group-item-heading h3 {
        color: #a1688a;
      }
    }
    &.setting-colour-1, .setting-colour-1 {
      .media-left, .list-group-item-heading h3 {
        color: #5F858F;
      }
    }
    &.setting-colour-2, .setting-colour-2 {
      .media-left, .list-group-item-heading h3 {
        color: #A8625C;
      }
    }
    &.setting-colour-3, .setting-colour-3 {
      .media-left, .list-group-item-heading h3 {
        color: #963E3E;
      }
    }
  }

  .list-group-item-heading {
    text-align: center;
    margin-bottom: 0;
    h3 {
      margin-bottom: 0;
    }
  }

  .list-group-item {

    a, a:hover {
      text-decoration: none;
      color: @text-color;
    }

    html.ios & {
      font: -apple-system-short-subheadline;
      line-height: 1.6;
    }

    .media-right {
      white-space: nowrap;
      color: #c7c7cc;
      .fa-chevron-right {
        margin-left: 5px;
      }
    }

    .media-colour-scheme-choice {
      padding-left: 0;
    }

    .media-body-disabled {
      color: #808080;
    }

    .media-body-colour-scheme-choice {
      color: white;
      font-weight: 400;
      width: initial;
    }

    .media-colour-scheme-block {
      padding: 4px 10px 4px 12px;
      margin-top: 3px;
      width: auto;
      min-width: 7em;
      display: inline-block;
      background: rgba(0, 0, 0, 0.4);
      font-size: 1.2em;
    }

    .media-left {
      padding-right: 10px * 1.2;
      i, strong {
        transform: scale(1.2);
      }
      &.feedback {
        .fa-stack {
          width: (18em / 14);
          height: 1em;
          line-height: 1em;
          vertical-align: text-top;
        }
        .fa-stack-2x {
          font-size: 1em;
        }
        .fa-stack-1x {
          font-size: 0.5em;
          line-height: 2em;
        }
      }
    }
  }

  .list-group-item--colour-scheme {
    padding-left: 0;
    height: 105px;
    margin-top: 25px;
    margin-bottom: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .list-group-item__choice--colour-scheme {
    background-size: cover;
    background-position: 50% 35%;
  }

  .list-group-item__choice--colour-scheme:focus {
    outline: none;
    box-shadow: inset rgba(0, 0, 0, 0.8) 0 0 20px;
  }

  .list-group-item__choice--colour-scheme-1 {
    background-image: url(/assets/images/bg01.jpg);
  }

  .list-group-item__choice--colour-scheme-2 {
    background-image: url(/assets/images/bg02.jpg);
  }

  .list-group-item__choice--colour-scheme-3 {
    background-image: url(/assets/images/bg03.jpg);
  }

  .list-group-item__choice--colour-scheme-4 {
    background-image: url(/assets/images/bg04.jpg);
  }

  .list-group-item__choice--colour-scheme-5 {
    background-image: url(/assets/images/bg05.jpg);
  }

  .list-group-item__choice--colour-scheme-1-preview {
    background-image: url(/assets/images/bg01-preview.png);
  }

  .list-group-item__choice--colour-scheme-2-preview {
    background-image: url(/assets/images/bg02-preview.png);
  }

  .list-group-item__choice--colour-scheme-3-preview {
    background-image: url(/assets/images/bg03-preview.png);
  }

  .list-group-item__choice--colour-scheme-4-preview {
    background-image: url(/assets/images/bg04-preview.png);
  }

  .list-group-item__choice--colour-scheme-5-preview {
    background-image: url(/assets/images/bg05-preview.png);
  }

  .list-group-item__choice--colour-scheme-6-preview {
    background-image: url(/assets/images/bg06-preview.jpg);
  }

  .list-group-item__choice--colour-scheme-7-preview {
    background-image: url(/assets/images/bg07-preview.jpg);
  }

  .list-group-item__choice--colour-scheme-8-preview {
    background-image: url(/assets/images/bg08-preview.jpg);
  }

  .grouped-option-title {
    padding-left: 14px;
    margin-top: 18px;
    margin-bottom: 4px;
    font-size: 0.9em;
    color: #5c5c5c;
  }

  .tab-bar {
    display: none;
  }
  html & {
    margin-bottom: 0;
  }
}

.in-settings .list-group.fixed {
  position: fixed;
  z-index: 1;
  width: 100%;

  & + * {
    padding-top: ~"calc(@{font-size-h3} * @{headings-line-height} + 20px + 16px)";
  }
}

// Modified from https://codepen.io/hansmaad/pen/zBdzxp

@md-radio-checked-color: #8c6e96;
@md-radio-border-color: rgba(0, 0, 0, 0.54);
@md-radio-size: 20px;
@md-radio-checked-size: 10px;
@md-radio-ripple-size: 8px;

@keyframes ripple {
  0% {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.0);
  }
  50% {
    box-shadow: 0 0 0 @md-radio-ripple-size rgba(0, 0, 0, 0.1);
  }
  100% {
    box-shadow: 0 0 0 @md-radio-ripple-size rgba(0, 0, 0, 0);
  }
}

.md-radio-colour-scheme-choice {
  margin-top: -1px;

  &.md-radio-inline {
    display: inline-block;
  }

  input[type="radio"] {
    display: none;
    &:checked + label:before {
      border-color: white;
    }
    &:checked + label:after {
      transform: scale(1);
    }
  }

  label {
    display: inline-block;
    height: @md-radio-size;
    position: relative;
    padding: 0 10px;
    margin-bottom: 0;
    cursor: pointer;
    vertical-align: middle;
    &:before, &:after {
      position: absolute;
      content: '';
      border-radius: 50%;
    }
    &:before {
      left: 0;
      top: 0;
      width: @md-radio-size;
      height: @md-radio-size;
      border: 2px solid white;
    }
    &:after {
      top: @md-radio-size / 2 - @md-radio-checked-size / 2;
      left: @md-radio-size / 2 - @md-radio-checked-size / 2;
      width: @md-radio-checked-size;
      height: @md-radio-checked-size;
      transform: scale(0);
      background: white;
    }
  }
}

.md-radio {
  margin-top: -1px;

  &.md-radio-inline {
    display: inline-block;
  }

  input[type="radio"] {
    display: none;
    &:checked + label:before {
      border-color: @md-radio-checked-color;
    }
    &:checked + label:after {
      transform: scale(1);
    }
  }

  label {
    display: inline-block;
    height: @md-radio-size;
    position: relative;
    padding: 0 10px;
    margin-bottom: 0;
    cursor: pointer;
    vertical-align: bottom;
    &:before, &:after {
      position: absolute;
      content: '';
      border-radius: 50%;
    }
    &:before {
      left: 0;
      top: 0;
      width: @md-radio-size;
      height: @md-radio-size;
      border: 2px solid @md-radio-border-color;
    }
    &:after {
      top: @md-radio-size / 2 - @md-radio-checked-size / 2;
      left: @md-radio-size / 2 - @md-radio-checked-size / 2;
      width: @md-radio-checked-size;
      height: @md-radio-checked-size;
      transform: scale(0);
      background: @md-radio-checked-color;
    }
  }
}

html.android {
  .md-radio--animated {
    input[type="radio"] {
      &:checked + label:before {
        animation: ripple 0.2s linear forwards;
      }
    }
  }
  .animation-enabled .md-radio--animated label {
    &:before, &:after {
      transition: all .3s ease;
      transition-property: transform, border-color;
    }
  }
}

.checkbox {
  @checkbox-size: 18px;
  display: inline-block;
  width: @checkbox-size;
  height: @checkbox-size;
  border: 2px solid @md-radio-border-color;
  border-radius: 3px;
  margin-top: 0;
  margin-bottom: 0;
  position: relative;
  top: 3px;

  .fa-check {
    @size: @checkbox-size - 2;
    color: #fff;
    opacity: 0;
    font-size: @size;
    line-height: @size;
    width: @size;
    height: @size;
    position: absolute;
    top: -1px;
    left: -1px;
  }

  &--checked {
    border-color: @md-radio-checked-color;
    background-color: @md-radio-checked-color;

    .fa-check {
      opacity: 1;
    }
  }

  &--disabled {
    opacity: .5;
  }
}
