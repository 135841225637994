.tab-bar {
  cursor: pointer;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #f2f2f2;
  border-top: 1px solid #d7d7d7;
  height: @start-tab-bar-height;
  padding-top: 5px;
  font-size: 13px;
  line-height: 1;
  color: #6e6e6e;
  z-index: 200;

  i {
    font-size: 18px;
    margin-bottom: 3px;
    position: relative;
  }

  .badge {
    background-color: #ff0404;
    position: absolute;
    right: 0;

    padding: 3px 5px;
    font-size: 10px;
  }
}

.tab-bar__tabs {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: nowrap;

  li {
    margin: 0;
    padding: 0;
    text-align: center;
    position: relative;

    &:hover {
      color: #555;
    }

    &:active {
      color: #333;
    }

    &.tab-bar-item--active {
      color: rgb(0, 122, 255);
    }

    &.disabled {
      color: #9c9c9c;
    }

  }
}

/* MSEdge fix - NEWSTART-1746 */
@supports (-ms-ime-align: auto) {
  .tab-bar__tabs {
    justify-content: space-around;
  }
}

.tab-label {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
