html.not-app .bg-stand-in {
  width: 100vw;
  height: 100vh;
  z-index: -1;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  position: fixed;
}

.marketing {
  display: none;
}

@media screen and (min-width: @screen-md-min) {
  .fixed-header, .nav.tab-bar, nav.tab-bar, .in-settings .list-group.fixed, .update-popup {
    position: fixed;
  }

  .id7-main-content-area {
    overflow-y: visible !important;
  }

  .marketing {
    user-select: text;
    position: fixed;
    top: 0;
    align-items: center;
    justify-content: center;
    height: 100vh;
    .inner-content {
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      padding: 2rem;
      font-size: 18px;
      color: #fff;

      h1 {
        color: white;
        font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
        font-size: 50px;
        text-align: center;
        letter-spacing: -0.2px;
        text-transform: uppercase;
        border-bottom: 0;
        padding-bottom: 0;
        margin-bottom: 11px;

        .light {
          opacity: 0.6;
        }
      }
      a {
        color: white;
        text-decoration-color: white;
      }
      .buttons {
        text-align: center;
        margin-top: 1rem;
      }
      .buttons a {
        text-decoration: none;
        display: inline-block;
        margin: 1rem;
      }
    }
    .app-dl {
      width: 160px;
    }
  }

  body, .fixed-header, .nav.tab-bar, nav.tab-bar, .in-settings .list-group.fixed, .add-tile-container, .update-popup {
    margin-right: 0;
    margin-left: 0;
    max-width: 100vw;
  }

  .tile--zoomed  .tile__header {
    margin-right: 0;
    margin-left: 0;
  }

  html {
    background: transparent;
  }

  html.not-app {
    background: #f9f9f9;
  }

  html.not-app .bg-stand-in {
    top: 0;
    height: 100vh;
  }

  #app-container {
    background: transparent;
  }

  .react-loading svg path {
    fill: #fff;
  }

  html:not(.app):not(.not-app) body { // loading condition
    background: transparent;
  }

  body, .fixed-header, .nav.tab-bar, nav.tab-bar, .in-settings .list-group.fixed,
  .add-tile-container, .update-popup {
    margin-left: auto;
  }

  body .react-loading {
    position: fixed;
    left: 0;
    right: 0;
  }

  .not-app {
    body {
      overflow-y: scroll;
      
      &.modal-open {
        overflow-y: hidden;
      }
    }

    #app-container:after, header.id7-page-header:before {
      content: "";
      position: fixed;
      margin-left: auto;
      left: 0;
      right: 0;
      z-index: 300;
    }

    html& .bg-stand-in {
      background-attachment: initial;
    }

    #app-container:after {
      bottom: 0;
    }

    header.id7-page-header:before {
      top: 0;
    }

    header.id7-page-header {
      border-top: 1px solid white;
      border-left: 1px solid white;
      border-right: 1px solid white;

    }

    .bg-stand-in {
      border-left: 1px solid #fff;
      border-right: 1px solid #fff;
    }
  }

  html .in-settings .bg-stand-in {
    background-image: none;
  }

  .top-page-notice {
    width: 100vw;
    position: fixed;
    left: 0;
    top:0;
    z-index: 310;
    &.dismiss {
      display: none;
    }
  }

  html body.masquerading {
    margin-top: @start-masthead-height;
  }
}

.desktop-rendition(@min-width, @desktop-rendition-vertical-border) {
  @desktop-rendition-width: @min-width / 2;
  @desktop-rendition-width-from-center: ~"calc(50vw -" (@desktop-rendition-width) ~")";
  @desktop-rendition-vertical-border-width: @desktop-rendition-width + 4;
  @desktop-rendition-vertical-border-right: @desktop-rendition-width + 2;

  .marketing {
    right: 50vw;
    .inner-content {
      max-width: @desktop-rendition-width;
    }
  }

  @media (min-width: @min-width) {

    .not-app {

      body {
        font-family: @font-family-sans-serif !important;
      }

      div.marketing {
        .inner-content {
          max-width: @desktop-rendition-width;
        }
        display: flex !important;
      }

      body, .fixed-header, .nav.tab-bar, nav.tab-bar, .in-settings .list-group.fixed,
      .add-tile-container {
        max-width: @desktop-rendition-width;
        margin-right: @desktop-rendition-width-from-center;
      }

      // Account for body's border
      .tile--zoomed  .tile__header {
        max-width: @desktop-rendition-width - 2;
        margin-right: ~"calc(50vw -" (@desktop-rendition-width - 1) ~")";
        margin-left: auto;
      }

      .fixed-header { top: @desktop-rendition-vertical-border; }
      .tab-bar { bottom: @desktop-rendition-vertical-border; }

      .main-content {
        padding-bottom: (@desktop-rendition-vertical-border + @start-tab-bar-height);
      }

      #app-container:after, header.id7-page-header:before {
        height: @desktop-rendition-vertical-border;
        max-width: @desktop-rendition-vertical-border-width;
        margin-right: ~"calc(50vw - @{desktop-rendition-vertical-border-right})";
        width: @desktop-rendition-vertical-border-width;
      }

      html& .bg-stand-in {
        width: @desktop-rendition-width;
      }

      .add-tile-container {
        bottom: @desktop-rendition-vertical-border;
      }

      .id7-main-content-header {
        padding-top: @desktop-rendition-vertical-border + 1;
      }

      .me-view-container, .me-view-container .tile--zoomed.tile {
        // Account for body's border
        width: @desktop-rendition-width - 2;
        margin-left: 1px;
      }

      .tile--map.tile--zoomed iframe {
        width: ~"calc(100% - 2px)";
        left: 1px;
        // 44px nav bar, 44px tab bar, 2 x 60px border
        height: ~"calc(100vh - @{start-masthead-and-tab-bar-height} - @{desktop-rendition-vertical-border} - @{desktop-rendition-vertical-border})";
      }

      .permission-request, .permission-warning {
        top: 0;
        bottom: auto;
      }

      .update-popup {
        bottom: @desktop-rendition-vertical-border + @start-tab-bar-height;
        max-width: @desktop-rendition-width - 8px;
        margin-right: ~"calc(50vw -" (@desktop-rendition-width) ~" + 4px)";
        left: auto;
      }
    }
  }
}

@media screen {
  .desktop-rendition(@screen-md-min, 10px);
  .desktop-rendition(@screen-lg-min, 10px);
}

@media screen and (min-height: 700px) {
  .desktop-rendition(@screen-md-min, 60px);
  .desktop-rendition(@screen-lg-min, 60px);
}


