.list-group-item--header {
  background-color: @behind-tiles-colour;
  h3 {
    font-size: inherit;
    margin: 0;
    color: inherit;
    line-height: inherit;
    font-weight: inherit;
  }
}

.tile .list-group-item--header {
  background-color: transparent;
  border: 0;
  padding-left: 0;
  font-weight: bold;
  .text--light {
    font-size: 0.92em;
  }
}

.tile-list-item {
  overflow: hidden;
  padding: 3px;
  margin: 0;
  html.ios & {
    line-height: 1.2em;
  }
}

.tile-list-item--with-separator {
  padding: 5px 3px;
  border-bottom: 1px solid rgba(255, 255, 255, .3);
}

.tile-list-group {
  margin-bottom: 6px;

  .list-group-item {
    html.ios & {
      line-height: 1;
    }
    &__title, &__location {
      max-width: 95%;
      max-width: calc(100% - @tile-gutter);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block;
    }

    &__date {
      float: right;

      &--left {
        margin-right: 8px;
        margin-left: 4px;
      }
    }

    &__text {
      display: block;
    }

    &--header {
      padding: 3px;
      border: 0;
      font-weight: bold;
      background-color: transparent;
    }
  }
}

.tile-list-item__organiser {
  .tile--large & {
    margin-top: 2px;
  }
}

.tile-list-item__location {
  white-space: nowrap;
}

.agenda-item {
  display: table-row;
  
  a[role="button"] {
    border-bottom: 1px dotted rgba(255, 255, 255, .5);
  }

  &__cell {
    display: table-cell;
    min-width: 3.3em; // width of "All day", to keep times and titles neatly aligned

    &__times {
      text-align: right;

      &__end-time {
        font-size: 85%; // ~11px for current 13px, but this should persist if we change that
      }
    }

    &.marker {
      min-width: auto;
      .fal .fas .far {
        margin: 0;
      }
    }
  }
}

.list-group {
  margin-bottom: 0;
}

.tile .list-group {
  margin-bottom: 1em;
}

ul.list-unstyled ul.list-unstyled {
  margin-top: 0;
}
