.center-aligned-icon(@base-size, @font-size) {
  @offset: (@base-size - @font-size) / 2;
  i {
    position: absolute;
    top: @offset;
    left: @offset;
    color: #f0f0f0;
    font-size: @font-size;
    line-height: @font-size;
    height: @font-size;
    width: @font-size;
    text-align: center;
  }
}

.left-aligned-icon(@base-size, @font-size) {
  .center-aligned-icon(@base-size, @font-size);
  i {
    left: (@base-size - @font-size) / 4;
  }
}

.tile__edit-control .fa-arrow-up {

  .tile--small.tile-size-supported--large &, .tile--small.tile-size-supported--tall & {
    transform: rotate(180deg);
  }
  .tile--small.tile-size-supported--wide & {
    transform: rotate(90deg);
  }

  .tile--wide & {
    transform: rotate(270deg);
  }
  .tile--wide.tile-size-supported--large &, .tile--wide.tile-size-supported--tall & {
    transform: rotate(180deg);
  }

  .tile--large & {
    transform: rotate(315deg);
  }
  .tile--large.tile-size-supported--tall & {
    transform: rotate(180deg);
  }

  .tile--tall & {
    transform: rotate(315deg);
  }
}

.tile__edit-control {
  @size: 44px;
  @icon-size: 36px;
  @font-size: 18px;

  display: none;
  position: absolute;
  z-index: 10;

  width: @size;
  height: @size;

  cursor: pointer;

  .icon {
    background-color: #404040;
    color: #fff;
    border: 2px solid #f0f0f0;
    border-radius: 100%;
    width: @icon-size;
    height: @icon-size;
    margin: (@size - @icon-size) / 2;
    opacity: 0.8;
    &:hover {
      background-color: #444;
    }
  }

  .center-aligned-icon(@size, @font-size);

  .animation-enabled & .fa-arrow-up {
    transition: transform .4s;
  }

  .edit-control-positions(6px);
}

.edit-control-positions(@padding) {
  &.top-left {
    top: @padding;
    left: @padding;
  }

  &.top-right {
    top: @padding;
    right: @padding;
  }

  &.bottom-left {
    bottom: @padding;
    left: @padding;
  }

  &.bottom-right {
    bottom: @padding;
    right: @padding;
  }
}

.add-tile-container {
  position: fixed;
  bottom: 0;
  width: 100%;

  .add-tile-button {
    @base-size: 56px;
    @font-size: 24px;
    line-height: @font-size;
    bottom: 60px;
    right: 25px;
    z-index: 200;
    width: @base-size * 2;
    height: @base-size;
    background-color: #8c6e96;
    border-radius: @base-size / 2;
    position: absolute;
    box-shadow: rgba(0, 0, 0, 0.16) 0 3px 10px, rgba(0, 0, 0, 0.23) 0 3px 10px;
    .left-aligned-icon(@base-size, @font-size);
    .app.android &, .app.ios & {
      bottom: 24px;
    }
    .add-tile-button__text {
      width: @base-size * 2;
      height: @base-size;
      padding: @base-size / 4 0;
      padding-left: @base-size * .55;
      color: #f0f0f0;
      line-height: 1;
      font-size: @font-size / 2;
      .num-hidden-tiles {
        font-size: @font-size * 1.1;
      }
      div {
        margin-left: 2px;
        display: inline-block;
      }
    }
  }
}

