.tile--weather .skycon {
  max-width: 40px;
  padding-top: 5px;
}

.tile__body {
  .tile--small.tile--agenda &,
  .tile--wide.tile--agenda &,
  .tile--small.tile--calendar &,
  .tile--wide.tile--calendar &,
  .tile--account & {
    & ul {
      line-height: 0.8 !important;
      margin-bottom: 8px;
    }
    .wbs-message > a {
      line-height: 1.4; // so dotted bottom-border is visible, only affects some browsers
    }
    .text-right {
      margin-bottom: 3px;
    }
  }
  .fal, .fas {
    opacity: 0.8;
    margin-right: 4px;

  }
}

.tile--account {
  .flex-container {
    display: flex;
    flex-direction: row;
  }

  .flex-photo {
    flex-grow: 0;
    width: 60px;
    margin-right: 8px;
    margin-left: 2px;
    margin-top: 2px;

    img {
      width: 100%;
      border-radius: 50%;
    }
  }

  .flex-content {
    flex-grow: 1;
    overflow: hidden;

    li {
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}

.tile--eap {
  .feature {
    display: flex;
    .date-and-name {
      margin-right: 16px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .more-info {
      margin-right: 12px;
    }
    .more-info, .feedback {
      white-space: nowrap;
      text-decoration: underline;
      i.fal i.fas i.far {
        margin-right: 4px;
      }
    }
  }
}

.tile--support {
  .tile__body p {
    margin-bottom: 0.5em;
  }
}
