.switch {
  display: inline-block;
  height: auto;
  font-size: 16px;
  padding-top: 0.1em;
}

.switch__length:before, .switch__length:after {
  transition: 0.4s background ease-in-out;
  display: block;
  position: absolute;
  content: "";
  left: 0.025em;
}

.switch__length:before {
  background-color: #a5a5a5;

  height: 0.8em;
  width: 2.25em;
  border-radius: 0.5em;
  top: 0.025em;
  bottom: 0.025em;
  content: "";
}

.switch__length:after {
  bottom: -0.35em;
  height: 1.375em;
  margin-left: -0.25em;
  width: 1.375em;
  border-radius: 100%;
  background-color: #ebebeb;
  box-shadow: 0 0.05em 0.125em rgba(0, 0, 0, 0.5);
  .animation-enabled & {
    transition: 0.3s margin ease-in-out, 0.3s background ease-in-out;
  }
}

.switch__length {
  width: 2.25em;
  height: 0.8em;
  margin-top: -10px;
  margin-bottom: -10px;
  margin-right: 0.375em;
  border-radius: 1.5em;
  display: block;
  position: relative;
  cursor: pointer;
}

.switch__checkbox {
  visibility: hidden;
  cursor: pointer;
  outline: none;
  user-select: none;
  position: absolute;
  left: -999999px; // off screen
}

.switch__checkbox:checked + .switch__length:before {
  background-color: rgba(91, 48, 105, 0.62);
}

.switch__checkbox:disabled + .switch__length:before {
  background-color: rgba(0, 0, 0, 0.12);
}

.switch__checkbox:checked + .switch__length:after {
  background-color: #5b3069;
  margin-left: 1.125em;
}

.switch__checkbox:disabled + .switch__length:after {
  background-color: #bdbdbd;
}


html.ios {
  // most styles from http://callmenick.com/_development/css-toggle-switch/

  .switch {
    display: inline-block;
  }

  .switch__length {
    padding: 0.05em;
    width: 3.1875em;
    height: 1.9375em;
    background-color: #ddd;
    margin-right: 0;
    border-radius: 1.5em;
    margin-top: -10px;
    margin-bottom: -10px;
  }

  .switch__checkbox + .switch__length:before {
    background-color: #f1f1f1;
    border-radius: 1.5em;
    right: 0.025em;
    height: auto;
    width: auto;
  }

  .switch__checkbox + .switch__length:after {
    width: 1.75em;
    height: 1.75em;
    background-color: #fff;
    border-radius: 100%;
    box-shadow: 0 0.05em 0.125em rgba(0, 0, 0, 0.3);
    top: 0.075em;
    left: 0.075em;
    bottom: 0.075em;
    content: "";
    margin-left: 0;
  }

  .animation-enabled .switch__checkbox + .switch__length:after {
    transition: margin 0.4s;
  }

  .switch__checkbox:checked + .switch__length:after {
    margin-left: 1.25em;
  }

  .switch__checkbox:checked + .switch__length:before {
    background-color: #8ce196;
  }

  .switch__checkbox:disabled + .switch__length {
    opacity: 0.5;
  }
}
