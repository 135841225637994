@import '@universityofwarwick/warwick-search-frontend/app/assets/css/variables';

@icon-font-path: '../lib/fontawesome-pro';
@fa-font-path: '../lib/fontawesome-pro';

@import '@universityofwarwick/warwick-search-frontend/app/assets/css/id7-tweaks';
@import '@universityofwarwick/warwick-search-frontend/app/assets/css/fallback';
@import '@universityofwarwick/warwick-search-frontend/app/assets/css/embed';
@import '@universityofwarwick/warwick-search-frontend/app/assets/css/search-form';
@import '@universityofwarwick/warwick-search-frontend/app/assets/css/search-results';
@import '@universityofwarwick/warwick-search-frontend/app/assets/css/chrome';

html.ios {
  .search-form {
    input {
      font: -apple-system-body;

      line-height: 1.6;
    }

    p {
      font: -apple-system-footnote;

      line-height: 1.6;
    }
  }

  .suggested-links .search-result .metadata {
    font: -apple-system-caption1;
  }

  .search-result {
    .hsep {
      h4 {
        font: -apple-system-body;

      }
    }

    .summary {
      span {
        font: -apple-system-body;

      }
    }
  }
}
