@import 'react-bootstrap-multiselect/less/bootstrap-multiselect.less';

.dropdown-menu > li > a {
  text-decoration: none !important;

  > label {
    // Give the options a bit more room
    padding-top: .75em;
    padding-bottom: .75em;

    > input[type="checkbox"] {
      margin-top: 5px;
      -webkit-transform: translateZ(0); // prevent checkbox flicker
    }
  }
}

.dropdown-menu > .active > a {
  background-color: @table-bg;

  &:hover, &:focus, &:active {
    background-color: @table-bg-hover;
  }
}

.dropdown-menu > .active label {
  font-weight: bold;
}
